
$button-height: 80px;
$icon-height: 48px;
body.kontakt {
    #cta-full {
        background:white;
    }
    .google-map {
        position: relative;
        overflow: auto;

        max-width: 100vw;
        overflow-x: hidden;

        &__map {
            min-height: 400px;
        }

        &__route {
            text-align: center;
            position: relative;
            top: -($button-height/2);
            margin-bottom: -($button-height/2);
            left: 50%;
            transform: translateX(-50%);
            display:flex;
            justify-content: center;
            align-items: center;

            &--simple {
                // simple route styles
            }
            @media (max-width: 767.98px) {
                display: none;
            }

            &--route {
                .input-group {
                    display: inline-flex;
                    width: auto;
                    &-append {
                        button {margin-top:0 !important;}
                    }
                    .form-control {
                        position: relative;
                        left: -1rem;
                        margin-right: -1rem;
                        display: inline-block;
                        width: auto;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        z-index: 1;
                        border-radius: 0;
                    }
                    button {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }

        &__route-icon {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #007bff;
            border-radius: 50%;
            height: $button-height;
            width: $button-height;
            line-height: $button-height;
            padding: 0;
            z-index: 2;
            svg {
                position: relative;
                height: $icon-height;
                width: $icon-height;
            }
        }

    }
}
